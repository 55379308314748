import './style.css'
import React, { Component } from 'react'
import classNames from 'classnames'
import { injectIntl } from 'react-intl'
import { Container, Row, Hidden, Visible, ScreenClassRender } from 'react-grid-system'
import GatsbyImage from 'gatsby-image'

import Space from '../../../components/Space'
import ArtworkCol from '../../ArtificialIntelligence/Strengths/components/ArtworkCol'
import ContentCol from '../../ArtificialIntelligence/Strengths/components/ContentCol'
import Logo from '../../ArtificialIntelligence/Strengths/components/Logo'
import Title from '../../ArtificialIntelligence/Strengths/components/Title'
import Description from '../../ArtificialIntelligence/Strengths/components/Description'

import activityIcon from '../../../img/icons/icon_activity.svg'
import shareIcon from '../../../img/icons/icon_share.svg'

const containerClass = classNames({
  'enterprise-arguments-container': true,
})

class ContentCreatorArguments extends Component {
  render() {
    return (
      <div className={containerClass}>
        <Container>
          {this.renderCostTraining()}
          <ScreenClassRender render={screenClass => (
            <Space type={['xs', 'sm'].includes(screenClass) ? 'm' : 'giant'} />
          )} />
          {this.renderComplicated()}
          <ScreenClassRender render={screenClass => (
            <Space type={['xs', 'sm'].includes(screenClass) ? 'm' : 'giant'} />
          )} />
          {this.renderDataSecurity()}
          <ScreenClassRender render={screenClass => (
            <Space type={['xs', 'sm'].includes(screenClass) ? 'm' : 'giant'} />
          )} />
          {this.renderControl()}
        </Container>
      </div>
    )
  }

  renderCostTraining = () => {
    const {
      data,
      intl: {
        formatMessage,
      },
    } = this.props

    return (
      <Row>
        <ContentCol
          md={4}
          mdOffset={0}
        >
          <Logo
            picture={activityIcon}
            alt={formatMessage({ id: 'enterprise.contentCreators.arguments.costTraining.alt' })}
          />
          <Space type="xs" />
          <Title
            id={formatMessage({ id: 'enterprise.contentCreators.arguments.costTraining.id' })}
            text={formatMessage({ id: 'enterprise.contentCreators.arguments.costTraining.title' })}
          />
          <Space type="m" />
          <Description text={formatMessage({ id: 'enterprise.contentCreators.arguments.costTraining.description' })} />
        </ContentCol>
        {this.renderArtwork(data.strength1Picture.childImageSharp.fluid, 459, formatMessage({ id: 'enterprise.contentCreators.arguments.costTraining.alt' }), 7, 1)}
      </Row>
    )
  }

  renderComplicated = () => {
    const {
      data,
      intl: {
        formatMessage,
      },
    } = this.props

    return (
      <Row>
        <Hidden xs sm>
          {this.renderArtwork(data.strength2Picture.childImageSharp.fluid, 459, formatMessage({ id: 'enterprise.contentCreators.arguments.complicated.alt' }), 7, 0)}
        </Hidden>

        <ContentCol
          md={4}
          mdOffset={1}
        >
          <Logo
            picture={shareIcon}
            alt={formatMessage({ id: 'enterprise.contentCreators.arguments.complicated.alt' })}
          />
          <Space type="xs" />
          <Title
            id={formatMessage({ id: 'enterprise.contentCreators.arguments.complicated.id' })}
            text={formatMessage({ id: 'enterprise.contentCreators.arguments.complicated.title' })}
          />
          <Space type="m" />
          <Description text={formatMessage({ id: 'enterprise.contentCreators.arguments.complicated.description' })} />
        </ContentCol>

        <Visible xs sm>
          {this.renderArtwork(data.strength2Picture.childImageSharp.fluid, 459, formatMessage({ id: 'ai.strengths.similarResults.alt' }), 7, 0)}
        </Visible>
      </Row>
    )
  }

  renderDataSecurity = () => {
    const {
      data,
      intl: {
        formatMessage,
      },
    } = this.props

    return (
      <Row>
        <ContentCol
          md={4}
          mdOffset={0}
        >
          <Logo
            picture={activityIcon}
            alt={formatMessage({ id: 'enterprise.contentCreators.arguments.dataSecurity.alt' })}
          />
          <Space type="xs" />
          <Title
            id={formatMessage({ id: 'enterprise.contentCreators.arguments.dataSecurity.id' })}
            text={formatMessage({ id: 'enterprise.contentCreators.arguments.dataSecurity.title' })}
          />
          <Space type="m" />
          <Description text={formatMessage({ id: 'enterprise.contentCreators.arguments.dataSecurity.description' })} />
        </ContentCol>
        {this.renderArtwork(data.strength3Picture.childImageSharp.fluid, 459, formatMessage({ id: 'enterprise.contentCreators.arguments.dataSecurity.alt' }), 7, 1)}
      </Row>
    )
  }

  renderControl = () => {
    const {
      data,
      intl: {
        formatMessage,
      },
    } = this.props

    return (
      <Row>
        <Hidden xs sm>
          {this.renderArtwork(data.strength4Picture.childImageSharp.fluid, 459, formatMessage({ id: 'enterprise.contentCreators.arguments.complicated.alt' }), 7, 0)}
        </Hidden>

        <ContentCol
          md={4}
          mdOffset={1}
        >
          <Logo
            picture={shareIcon}
            alt={formatMessage({ id: 'enterprise.contentCreators.arguments.control.alt' })}
          />
          <Space type="xs" />
          <Title
            id={formatMessage({ id: 'enterprise.contentCreators.arguments.control.id' })}
            text={formatMessage({ id: 'enterprise.contentCreators.arguments.control.title' })}
          />
          <Space type="m" />
          <Description text={formatMessage({ id: 'enterprise.contentCreators.arguments.control.description' })} />
        </ContentCol>

        <Visible xs sm>
          {this.renderArtwork(data.strength4Picture.childImageSharp.fluid, 459, formatMessage({ id: 'enterprise.contentCreators.arguments.control.alt' }), 7, 0)}
        </Visible>
      </Row>
    )
  }

  renderArtwork = (src, height, alt, md = 6, mdOffset = 0) => {
    return (
      <ArtworkCol
        xs={12}
        md={md}
        mdOffset={mdOffset}
      >
        <Visible xs sm>
          <Space type="s" />
        </Visible>
        <GatsbyImage
          fluid={src}
          alt={alt}
          style={{
            height: 'auto',
            maxHeight: height,
            maxWidth: '100%',
          }}
        />
      </ArtworkCol>
    )
  }
}

export default injectIntl(ContentCreatorArguments)
